import { createTheme, responsiveFontSizes } from "@mui/material"
import colors from "tailwindcss/colors"
import { globalStyleOverrides } from "../utils/globalStyleOverrides"
import localFont from "@next/font/local"

// Font files can be colocated inside of `pages`
export const evolve = localFont({
  src: [
    {
      path: "./fonts/made_evolve_sans/MADEEvolveSansRegular.otf",
      weight: "400",
    },
    {
      path: "./fonts/made_evolve_sans/MADEEvolveSansBold.otf",
      weight: "500",
    },
  ],
})

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: evolve.style.fontFamily,
    body2: { fontSize: "1rem" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: globalStyleOverrides,
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          className: "p-4",
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.red[400],
      contrastText: colors.white,
    },
    secondary: {
      main: colors.orange[400],
      contrastText: colors.white,
    },
    info: {
      main: colors.white,
      // contrastText: colors.black,
    },
    error: {
      main: colors.rose[400],
      contrastText: colors.white,
    },
  },
})

export default responsiveFontSizes(theme)
