import "../styles/globals.css"
import "../styles/device.css"
import type { AppProps } from "next/app"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import initAuth from "@/data/auth/initAuth" // the module you created above
import { init } from "@/data/initFb"
import LogRocket from "logrocket"
import * as Sentry from "@sentry/browser"
import { setLogRocketSessionUrl } from "@/page_helpers/session_recording/SessionRecorder"
import { UserbackButton } from "@/views/app/UserbackButton"
import { useQueryObsWithEffect } from "@/views/view_builder/queryObs"
import { RenderIdContext } from "@/views/view_builder/RenderIdContext"
import { v4 as uuidv4 } from "uuid"
import { CacheProvider, EmotionCache } from "@emotion/react"
import createEmotionCache from "utils/createEmotionCache"
import React, { useMemo } from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import { CssBaseline, ThemeProvider } from "@mui/material"
import theme from "@/utils/theme"
import { StyledEngineProvider } from "@mui/material/styles"
import { isServerside } from "@/helpers/isServerside"

init()
initAuth()

// export function reportWebVitals(metric) {
//   console.log("metrics", metric)
// }

const isProd = process.env.NODE_ENV === "production"

// only initialize when in the browser
if (typeof window !== "undefined") {
  if (isProd) {
    LogRocket.init("rarkhs/hylite")
    console.log("initializing logging")
    LogRocket.getSessionURL((sessionURL) => {
      console.log("seting")
      setLogRocketSessionUrl(sessionURL)
      Sentry.configureScope((scope) => {
        console.log("log rocket", sessionURL)
        scope.setExtra("sessionURL", sessionURL)
      })
    })

    Sentry.init({
      beforeSend(event) {
        const logRocketSession = LogRocket.sessionURL
        if (logRocketSession !== null) {
          event.extra["LogRocket"] = logRocketSession
          return event
        } else {
          return event
        }
      },
    })
  } else {
    setLogRocketSessionUrl(null)
  }
}

const clientSideEmotionCache = createEmotionCache()

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  useQueryObsWithEffect()

  const { locale, query } = useRouter()

  const app = useMemo(() => {
    const uid = uuidv4()
    const component = <Component {...pageProps} />
    return (
      <RenderIdContext.Provider value={uid}>
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
          {/* <UserbackButton /> */}
          {component}
        </>
      </RenderIdContext.Provider>
    )
  }, [locale, query.slug, pageProps])

  if (!isServerside()) {
    const rootElement = window.document.getElementById("__next")
    theme.components = {
      ...theme.components,
      ...{
        MuiPopover: {
          defaultProps: {
            container: rootElement,
          },
        },
        MuiPopper: {
          defaultProps: {
            container: rootElement,
          },
        },
        MuiModal: {
          defaultProps: {
            container: rootElement,
          },
        },
      },
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {app}
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </>
  )
}
export default MyApp
