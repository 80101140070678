import { obsToNamedParamObs } from "@/data/builders/obsToNamedParamObs"
import { isUndefined } from "@/helpers/isUndefined"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { BehaviorSubject, filter } from "rxjs"
import { isServerside } from "@/helpers/isServerside"

const cachedQueryObs = new BehaviorSubject(
  undefined as Record<string, string | string[]>
)

export const queryObsCacheName = "queryObs"
const alwaysUseCache = isServerside()
export const getQueryObs = () =>
  obsToNamedParamObs(
    cachedQueryObs.pipe(filter((_) => !isUndefined(_))),
    queryObsCacheName
  ).cloneWithCaching(undefined, alwaysUseCache)

export const useQueryObsWithEffect = () => {
  const router = useRouter()
  cachedQueryObs.next(router.query)
  useEffect(() => {
    cachedQueryObs.next(router.query)
  }, [router.query])
  return cachedQueryObs
}
