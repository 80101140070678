// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

console.log("ENV:", process.env.NODE_ENV)
import { BrowserTracing } from "@sentry/tracing"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://a17b201767ae4788a80e874d8e83d6f1@o1120061.ingest.sentry.io/6155131",
    // Adjust this value in production, or use tracesSampler for greater control
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
