import { User } from "@/data/types/User"
import { Store } from "@/data/types/Store"
import { LocationEmployee } from "@/data/types/LocationEmployee"
import { Hylite } from "@/data/types/Hylite"
import { buildConverterForType } from "../builders/buildConverterForType"
import { FirestoreDataConverter } from "@firebase/firestore"
import { Company } from "../types/Company"
import { PrivateUser } from "../types/PrivateUser"
import { PublicUser } from "../types/PublicUser"
import { ImportCsvJob } from "../types/ImportCsvJob"
import { ImportJobChangeRecord } from "../types/ImportJobChangeRecord"
import { EmailSendRecord } from "../types/EmailSendRecord"
import { SessionRecord } from "../types/SessionRecord"
import { Model } from "../baseTypes/Model"

export const CollectionsWithConverters: {
  [key in keyof CollectionModels]: FirestoreDataConverter<CollectionModels[key]>
} = {
  privateUser: buildConverterForType<PrivateUser>(),
  publicUser: buildConverterForType<PublicUser>(),
  locationEmployee: buildConverterForType<LocationEmployee>(),
  store: buildConverterForType<Store>(),
  hylite: buildConverterForType<Hylite>(),
  company: buildConverterForType<Company>(),
  importCsvJob: buildConverterForType<ImportCsvJob>(),
  importJobChangeRecord: buildConverterForType<ImportJobChangeRecord>(),
  emailSendRecord: buildConverterForType<EmailSendRecord>(),
  dev_SessionRecord: buildConverterForType<SessionRecord>(),
}

export type AllModels = {
  locationEmployee: LocationEmployee
  store: Store
  hylite: Hylite
  company: Company
  privateUser: PrivateUser
  publicUser: PublicUser
  user: User
  importCsvJob: ImportCsvJob
  importJobChangeRecord: ImportJobChangeRecord
  emailSendRecord: EmailSendRecord
  dev_SessionRecord: SessionRecord
}

export type CollectionModels = Omit<AllModels, "user">
